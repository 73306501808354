<template>
<div class="ui-pc">
    <div class="ui-pc__content">
        <header class="ui-pc__header">
            <span class="ui-pc__close-btn" @click="$emit('closePCFormEvent')">&times;</span>
            <span class="ui-pc__status">
                <img class="ui-pc__status-icon" :src="require(`@/assets/images/eligble-icon.png`)" />
            </span>
        </header>

        <div class="ui-pc__body">
            <p class="ui-pc__title">{{ $t("pc_heading") }}</p>
            <p class="ui-pc__intro-text test" v-html="$t('pc_intro_text')"></p>

            <form class="ui-pc__form" v-on:submit.prevent="post(form)">
                <!-- Name input -->
                <text-input class="ui-pc__form-input" :placeholder="$t('pc_name_placeholder')" name="name" v-model="$v.form.name.$model">
                    <template slot="label"><b>{{ $t("pc_name") }}</b></template>
                    <template v-if="$v.form.name.$error" slot="error-msg">
                        <i class="input-error-icon fas fa-exclamation-circle"></i>
                        {{ $t("pc_name_error") }}
                    </template>
                </text-input>

                <!-- Telephone Number input  -->
                <text-input class="ui-pc__form-input" inputType="tel" :placeholder="$t('pc_tel_placeholder')" name="phone" v-model="$v.form.sms.$model">
                    <template slot="label"><b>{{ $t("pc_tel") }}</b></template>
                    <template v-if="$v.form.sms.$error" slot="error-msg">
                        <i class="input-error-icon fas fa-exclamation-circle"></i>
                        {{ $t("pc_tel_error") }}
                    </template>
                </text-input>

                <!-- Email Address input -->
                <text-input class="ui-pc__form-input" inputType="email" :placeholder="$t('pc_email_placeholder')" name="email" v-model="$v.form.email.$model">
                    <template slot="label">
                        <b v-html="$t('pc_email')"></b>
                    </template>
                    <template v-if="$v.form.email.$error" slot="error-msg">
                        <i class="input-error-icon fas fa-exclamation-circle"></i>
                        {{ $t("pc_email_error") }}
                    </template>
                </text-input>

                <!-- Country Dropdown  -->
                <select-dropdown class="ui-pc__form-input" v-bind:value="countrySelectVal" @change="onCountrySelect($event)" :options="countries" labelFor="locations" ariaLabel="Select location">
                    <template slot="label"><b>{{ $t("pc_site") }}</b></template>
                    <template slot="select-text">{{ $t("pc_location") }}</template>
                    <template v-if="$v.form.country.$error" slot="error-msg"> <i class="dropdown-error-icon fas fa-exclamation-circle"></i>{{ $t("pc_dropdown_error") }}</template>
                </select-dropdown>

                <!-- State/City Dropdown  
                <select-dropdown class="ui-pc__form-input" v-bind:value="stateSelectVal" @change="onStateSelect($event)" :options="states" labelFor="states" ariaLabel="Select State">
                    <template slot="select-text"><b>{{ $t("pc_city") }}</b></template>
                    <template v-if="$v.form.state.$error" slot="error-msg"> <i class="dropdown-error-icon fas fa-exclamation-circle"></i>{{ $t("pc_dropdown_error") }}</template>
                </select-dropdown>
                -->

                <!-- Site Dropdown  -->
                <select-dropdown class="ui-pc__form-input" v-bind:value="form.site" @change="onSiteSelect($event)" :options="sites" labelFor="sites" ariaLabel="Select Site">
                    <template slot="select-text"><b>{{ $t("pc_site") }}</b></template>
                    <template v-if="$v.form.site.$error" slot="error-msg"> <i class="dropdown-error-icon fas fa-exclamation-circle"></i>{{ $t("pc_dropdown_error") }}</template>
                </select-dropdown>

                <!-- Consent Box  -->
                <!-- Consent Box  -->
                <consent-box v-bind:value="form.consent" @change="onConsentChange($event)">
                    <span v-html='$t("pc_consent")' />
                    <template v-if="$v.form.consent.$error" slot="error-msg">
                        <i class="consent-error-icon fas fa-exclamation-circle"></i>
                        {{ $t("pc_consent_error") }}
                    </template>
                </consent-box>

                <centered-layout class="submit-btn-layout">
                    <ui-button :isDisabled="$v.form.$invalid" class="btn--primary pc-submit-btn">
                        {{ $t("pc_submit") }}
                    </ui-button>
                </centered-layout>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import UiButton from "@/components/UiButton.vue";
import TextInputVue from "@/components/TextInput.vue";
import ConsentBoxVue from "../../components/ConsentBox.vue";
import CenteredLayoutVue from "../../components/layout/CenteredLayout.vue";
import SelectDropdownVue from "@/components/SelectDropdown.vue";
import axios from "axios";
import {
    patientConnectUrl,
    patientConnectHeaders,
    sitesTwo
} from "@/config";
import {
    required,
    email,
    numeric,
    sameAs
} from 'vuelidate/lib/validators'

export default {
    name: "Home",
    components: {
        "ui-button": UiButton,
        "text-input": TextInputVue,
        "select-dropdown": SelectDropdownVue,
        "consent-box": ConsentBoxVue,
        "centered-layout": CenteredLayoutVue
    },
    data() {
        return {
            form: {

                // required data to be sent to Longboat
                name: '',
                sms: '',
                email: '',
                site: null,

                // the data below don't need to be sent but they are anyways
                country: null,
                state: null,
                siteId: null,
                consent: null,
            },
            countrySelectVal: null,
            stateSelectVal: null,
            selectedCountryId: null,
            selectedStateId: null,

            countries: sitesTwo,
            states: [],
            sites: []
        }
    },
    validations: {
        form: {
            name: {
                required,
            },
            sms: {
                required,
                numeric,
            },
            email: {
                email,
            },

            country: {
                required,
            },
            /*
            state: {
                required,
            },
            */
            site: {
                required,
            },

            consent: {
                sameAs: sameAs(() => true),
            }

        }
    },
    methods: {
        onCountrySelect($event) {
            this.countrySelectVal = $event.target.value;
            this.form.country = $event.target.options[$event.target.selectedIndex].text;

            this.$v.form.country.$touch()

            this.selectedCountryId = $event.target.selectedIndex

            this.sites = []; // reinitialize the 'sites' list

            if (this.selectedCountryId != 0) {
                this.sites = this.countries[this.selectedCountryId - 1].states;
            } else {
                // if '-- select --' option was selected
                this.form.country = ''
            }

            //reinitialize data
           // this.form.state = ''
            this.form.site = ''
            this.form.siteId = null
        },
        /*
        onStateSelect($event) {
            this.stateSelectVal = $event.target.value;
            this.form.state = $event.target.options[$event.target.selectedIndex].text;

            this.$v.form.state.$touch()

            this.selectedStateId = $event.target.selectedIndex

            this.sites = []; // reinitialize the 'sites' list if the state id has changed
            if (this.selectedCountryId != 0 && this.selectedStateId != 0) {
                this.sites = this.countries[this.selectedCountryId - 1].states[this.selectedStateId - 1].sites;
            } else {
                // if '-- select --' option was selected
                this.form.state = ''
            }
        },
        */
        onSiteSelect($event) {
            this.form.site = $event.target.value;
            this.form.siteId = $event.target.value

            this.$v.form.site.$touch()
        },
        onConsentChange($event) {
            this.form.consent = $event;
            this.$v.form.consent.$touch()
        },
        post(form) {
            console.log("this is the content of form: ", JSON.stringify(form));

            this.$v.$touch();
            const isFormInvalid = this.$v.$invalid

            if (isFormInvalid) {
                console.log("form is invalid")
                return
            }

            console.log("form is valid")

            let formData = {
                'name': this.form.name,
                'sms': this.form.sms,
                'email': this.form.email,
                'site': this.form.site,
            };

            axios.post(this.getPatientConnectUrl(), formData, {
                    headers: patientConnectHeaders
                })
                .then((res) => {
                    console.log("this is the response: ", res);
                    this.$emit("formSubmittedEvent");
                })
                .catch((error) => {
                    console.log("error just occured: ", error);
                    this.$emit("formSubmissionErrorEvent");
                })
                .finally(() => {
                    //Perform action in always
                });

        },

        getPatientConnectUrl() {
            const isPatientConnectLocal = process.env.VUE_APP_IS_PATIENT_CONNECT_LOCAL;
            if (isPatientConnectLocal === "false") {
                return patientConnectUrl.absolutePath
            } else {
                return patientConnectUrl.rel
            }
        }

    },
};
</script>

<style lang="scss">
.ui-pc {
    width: 100%;
    max-width: 900px;

    &__header {
        position: relative;
        background: #4e5157;
        padding: 40px;
        color: white;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    &__status {
        width: 80px;
        height: 80px;
        background-color: $primary-color;
        position: absolute;
        left: 50%;
        margin-left: -40px;
        bottom: -40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    &__title {
        font-size: 30px;
        margin: 0;
        margin-top: 24px;
        margin-bottom: 12px;
        text-align: center;
        color: $primary-color;
    }

    &__content {
        background-color: #fefefe;
        max-width: 700px;
        margin: 0 auto;
        width: 100%;
        border-radius: 8px;

        @media only screen and (min-width: 676px) {
            margin: 15% auto;
        }
    }

    &__close-btn {
        position: absolute;
        top: 0px;
        right: 0;
        color: white;
        font-size: 28px;
        font-weight: bold;
        padding: 8px 16px;

        &:hover,
        &focus {
            color: black;
            text-decoration: none;
            cursor: pointer;
        }
    }

    &__body {
        padding: 32px 16px;
        text-align: center;

        height: 70vh;
        overflow-y: auto;

        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */

        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }

        .privacy-link {
            color: $accent-color !important;
        }
    }

    &__intro-text {
        padding-bottom: 32px;
        margin-top: 0;
        max-width: 600px;
        margin: auto;
    }

    &__form {
        max-width: 450px;
        margin: 0 auto;
        text-align: left;

        &-input {
            margin-bottom: 16px;
        }
    }
}

.select-dropdown {
    margin-bottom: 16px;
}

.submit-btn-layout {
    padding: 16px 0;
}

.ui-input {
    &__label {
        color: $primary-color;
    }
}

.dropdown {
    &__label {
        color: $primary-color;
        margin-bottom: 0;
    }
}

.ui-custom-checkbox input:checked~.checkmark {
    background-color: $pc-consent-box-color;
    border: 2px solid $pc-consent-box-color;
    top: 4px;
    height: 12px;
    width: 12px;

}

.pc-submit-btn {
    &:disabled {
        background-color: #a8b1bd !important;

        &:hover {
            background-color: #a8b1bd !important;
        }
    }

    background-color: $pc-submit-btn-color !important;

    &:hover {
        background-color: darken($pc-submit-btn-color, 10%) !important;
    }
}
</style>
