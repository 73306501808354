<template>
<section class="eligibility-section ui-section">
    <div class="ui-max-width">
        <ui-head class="txt-center">
            <template #head><h3 v-html="$t('elig_head')"></h3></template>
            <template #subhead>{{$t('elig_head_p')}}</template>
        </ui-head>

        <question-tab v-for="(item, fIndex) in form" :key="'que' + fIndex" :isActive="item.isActive" class="ui-question-tab" :class="{ 'last-tab': form.length == fIndex + 1 }">
            <template slot="text"> {{ item.question }}</template>
            <template slot="options">
                <div class="select-options">
                    <select-btn v-for="(choice, index) in item.choices" :key="'sel' + index" class="select-btn" :label='fIndex + choice.value' :groupName='"que" + fIndex' :value='choice.value' @choiceChange="processChoice(fIndex,$event)"> {{ choice.text }} </select-btn>
                </div>
            </template>
        </question-tab>

        <centered-layout>
            <ui-button :isDisabled="!isEligButtonEnabled" class="elig-check-btn btn--secondary" @click="processEligibility(form)">
                {{$t('check_elig')}}
            </ui-button>
        </centered-layout>

        <modal-layout v-if="isInEligibilityAlertVisible">
            <alert-layout @closeAlertEvent='isInEligibilityAlertVisible = false' :alertIcon="require(`@/assets/images/ineligble-icon.png`)">
                <template #title>{{ $t('not_eligible') }}</template>
                <template #body>{{ $t('not_eligible_desc') }}</template>
            </alert-layout>
        </modal-layout>


        <!-- PATIENT CONNECT FORM -->
        <modal-layout v-if="isPatientConnectFormVisible">
            <patient-connect @closePCFormEvent='isPatientConnectFormVisible = false' @formSubmittedEvent='onFormSubmissionSuccess' @formSubmissionErrorEvent='showFormSubmissionErrorAlert'></patient-connect>
        </modal-layout>

        <!-- Display this modal if PC Form submission is successful -->
        <modal-layout v-if="isFormSubmissionSuccessAlertVisible">
            <alert-layout @closeAlertEvent='isFormSubmissionSuccessAlertVisible = false' :alertIcon="require(`@/assets/images/eligble-icon.png`)">
                <template #title>{{ $t('pc_success_h') }}</template>
                <template #body>{{ $t('pc_success') }}</template>
            </alert-layout>
        </modal-layout>

        <!-- Display this modal if PC Form submission is unsuccessful -->
        <modal-layout v-if="isFormSubmissionErrorAlertVisible">
            <alert-layout @closeAlertEvent='isFormSubmissionErrorAlertVisible = false' :alertIcon="require(`@/assets/images/ineligble-icon.png`)">
                <template #title>{{ $t('pc_errorh1') }}</template>
                <template #body>
                 <p class="mb-1" v-html="$t('pc_errorp')"></p>
                 <p v-html="$t('pc_errorp1')"></p>
                </template>
            </alert-layout>
        </modal-layout>

    </div>
</section>
</template>

<script>
import UiButton from '@/components/UiButton.vue'

import ModalLayout from '@/components/layout/ModalLayout.vue'
import QuestionTab from '@/components/QuestionTab'
import SelectButtonVue from '@/components/SelectButton.vue'
import CenteredLayoutVue from '../../components/layout/CenteredLayout.vue'
import PatientConnectVue from './PatientConnectTwo.vue'
import AlertLayoutVue from '../../components/layout/AlertLayout.vue'
import UiHeadingVue from '../../components/UiHeading.vue'
import {
    eligibilityForm
} from '@/config'

export default {
    name: 'Home',
    components: {
        "ui-button": UiButton,
        "modal-layout": ModalLayout,
        "question-tab": QuestionTab,
        "select-btn": SelectButtonVue,
        "centered-layout": CenteredLayoutVue,
        "patient-connect": PatientConnectVue,
        "alert-layout": AlertLayoutVue,
        "ui-head": UiHeadingVue

    },
    created(){
        this.addIsActiveProperty(this.form)
    },
    data() {
        return {
            isPatientConnectFormVisible: false,
            isInEligibilityAlertVisible: false,
            isFormSubmissionErrorAlertVisible: false,
            isFormSubmissionSuccessAlertVisible: false,
            isEligButtonEnabled: false,
            form: eligibilityForm(this)
        }
    },
    methods: {
        addIsActiveProperty(form){
            for (let i= 0; i < form.length; i++){
                this.$set(form[i], 'isActive', false);
            }
        },
        onFormSubmissionSuccess: function () {
            this.isPatientConnectFormVisible = false;
            this.isFormSubmissionSuccessAlertVisible = true;
        },

        showFormSubmissionErrorAlert: function () {
            this.isFormSubmissionErrorAlertVisible = true;
        },

        processChoice: function (queLabel, value) {
            this.form[queLabel].userAnswer = value; // this adds a new 'userAnswer' property to the 'form'
            this.form[queLabel].isActive = true;
            this.checkIsEligButtonEnabled();
        },

        checkIsEligButtonEnabled: function () {
            let isAllQuestionAnswered = true ;
            for (const element of this.form){
                isAllQuestionAnswered = isAllQuestionAnswered && element.isActive;
            }
            if (isAllQuestionAnswered) {
                this.isEligButtonEnabled = true
            } else {
                this.isEligButtonEnabled = false
            }

        },

        isValid: function (input) {
            let isUserAnswerValid = false
            input.correctAnswers.forEach((value) => {
                if (value == input.userAnswer)
                    isUserAnswerValid = true
            })
            return isUserAnswerValid;
        },

        isAllValid: function (param) {
            let isUserAnswersAllCorrect = true
            param.forEach((x) => {
                let _isValid = this.isValid(x)
                isUserAnswersAllCorrect = isUserAnswersAllCorrect && _isValid
            })
            return isUserAnswersAllCorrect
        },

        processEligibility: function (param) {

            if (this.isAllValid(param)) {
                console.log("you are eligible")
                this.isPatientConnectFormVisible = true;
            } else {
                console.log("you are not eligible")
                this.isInEligibilityAlertVisible = true;
            }

        }
    },
}
</script>

<style lang="scss">

.ui-question-tab {
    margin-bottom: 16px;
}

.last-tab:before {
    content: none;
}

.select-options {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.select-btn {
    margin-top: 16px;
    margin-right: 16px;
}

.eligibility-section {
    background: $elig-section-bg-color;
}

.question-tab {

    &--active {

        &:before,
        .question-tab__indicator {
            background-color: $elig-indicator-bg-color;
        }
    }

}

.radio__input:checked+.radio__label {
    background-color: $elig-select-btn-color-selected;
}

.radio__input:focus+.radio__label {
    border: 2px dashed $elig-select-btn-color-onfocus;
}

.radio__label:hover {
    background-color: $elig-select-btn-color-onhover;
}

.elig-check-btn {

&:disabled {
    background-color: #a8b1bd !important;
        &:hover {
        background-color: #a8b1bd !important;
        }
    }
    background-color: $elig-check-btn-color !important ;

    &:hover {
        background-color: darken($elig-check-btn-color, 10%) !important;
    }

}
</style>
