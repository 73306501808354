<template>
<div ref='ui-mn-wrapper' id='js-ui-mn-wrapper' class="ui-mn-wrapper">
    <div class="ui-header-max-width">
        <div class='ui-mn-outer'>

            <a href="#js-home" @click.self="closeMenuPanel()">
                <img class="ui-mn-logo " :src="require(`@/assets/images/${logoSrc}`)" />
            </a>

            <div class="ui-mn-options">
                <lang-dropdown :locales="locales" :selectedLocale="selectedOption" class="lang-dropdown" @localeChange="handleLocaleChangeEvent"></lang-dropdown>
                <div class="menu-text">{{$t('menu')}}</div>
                <div ref="ui-mn-hamburger" id="js-ui-mn-hamburger" class="ui-mn-hamburger" @click="openMenuPanel">
                    <span></span>
                </div>
            </div>

        </div>
        <div ref="ui-mn-list-wrapper" id="js-ui-mn-list-wrapper" class="ui-mn-list-wrapper">
            <div class="dl-navbar-inner-wrapper ui-header-max-width">
                <div ref="ui-mn-close-btn" id="js-ui-mn-close-btn" class="ui-mn-close-btn" @click="closeMenuPanel">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </div>
                <ul class='ui-mn-list'>
                    <li class="ui-mn-list-item">
                        <lang-dropdown :locales="locales" :selectedLocale="selectedOption" class="lang-dropdown show-in-mobile" @localeChange="handleLocaleChangeEvent"></lang-dropdown>
                    </li>
                    <li class="ui-mn-list-item"><a href='#js-home' @click.self="closeMenuPanel()" v-smooth-scroll><i class="fa fa-home" aria-hidden="true"></i></a></li>
                    <li class="ui-mn-list-item"><a href='#js-about' @click="closeMenuPanel()" v-smooth-scroll>{{$t('nav_one')}}</a></li>
                    <li class="ui-mn-list-item"><a href='#js-eligible' @click="closeMenuPanel()" v-smooth-scroll>{{$t('nav_two')}}</a></li>
                    <li class="ui-mn-list-item"><a href='#js-center' @click="closeMenuPanel()" v-smooth-scroll>{{$t('nav_three')}}</a></li>
                    <li class="ui-mn-list-item"><a href='#js-faqs' @click="closeMenuPanel()" v-smooth-scroll>{{$t('nav_four')}}</a></li>
                    

                </ul>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    setCurrentLocale,
    getpreferredLocales
} from "@/plugins/i18n";
import {
    logoPath,
} from "@/config";
import LocaleSwitcherVue from '../../components/LocaleSwitcher.vue';
import {
    handleLocaleChangeEvent,
    setPageTitle
} from "@/common/utils";

export default {
    name: 'ui-menu',
    components: {
        "lang-dropdown": LocaleSwitcherVue
    },
    mounted() {
        this.registerEvent();
    },
    beforeDestroy() {
        this.removeEvent()
    },
    data() {
        return {
            locales: getpreferredLocales(),
            logoSrc: logoPath,
            selectedOption: this.$i18n.locale,
        };
    },
    methods: {
        getScrollTop() {
            return window.pageYOffset || document.documentElement.scrollTop
        },
        openMenuPanel: function () {
            this.$refs['ui-mn-list-wrapper'].classList.add("dl-open");
        },
        closeMenuPanel: function () {
            this.$refs['ui-mn-list-wrapper'].classList.remove("dl-open");
        },
        processScroll() {
            if (this.getScrollTop() > 20) {
                this.$refs['ui-mn-wrapper'].classList.add("ui-scroll");
            } else {
                this.$refs['ui-mn-wrapper'].classList.remove("ui-scroll");
            }
        },
        registerEvent() {
            window.addEventListener('scroll', this.processScroll)
        },
        removeEvent() {
            window.removeEventListener('scroll', this.processScroll)
        },
        handleLocaleChangeEvent(locale) {
            setCurrentLocale(locale);
            setPageTitle(this.$t('site_title'))
            this.selectedOption = locale
            handleLocaleChangeEvent(this, locale);
            this.$emit('localeHasChangedEvent');
        }

    },
}
</script>

<style lang="scss" scoped>
.ui-mn-wrapper {
    background-color: #fff;
}

.ui-mn-hamburger span,
.ui-mn-hamburger span:after,
.ui-mn-hamburger span:before {
    background: $menu-hamburger-color;
}

.ui-mn-list-wrapper {
    background-color: $menu-panel-bg;
}

.ui-mn-close-btn {
    border: 3px solid #fff;
    color: #fff;
}

.ui-mn-list-item a {
    color: #fff;
    text-decoration: none;
}

.ui-mn-list-item a:hover {
    color: $menu-item-onhover;
}

.ui-mn-wrapper {
    width: 100%;
    z-index: 9;
    position: fixed;
    top: 0;
    box-shadow: -6px 5px 5px 0px rgba(0, 0, 0, 0.05);
    padding: 16px;
    transition: all .5s ease;
    box-sizing: border-box;
}

.ui-mn-wrapper .ui-mn-outer {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ui-mn-logo {
    max-height: 60px;
    transition: all .4s ease;
    width: 150px;

    @media screen and (min-width: 767px) {
        width: 200px;
    }
}

/*STICKY*/
.ui-mn-wrapper.ui-scroll {
    padding: 8px 16px;
}

.ui-mn-wrapper.ui-scroll .ui-mn-logo {
    width: 150px;
}

/*STICKY*/
.ui-mn-hamburger {
    height: 28px;
    width: 28px;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px 16px;
}

.ui-mn-hamburger span,
.ui-mn-hamburger span::before,
.ui-mn-hamburger span::after {
    position: absolute;
    content: "";
    width: 28px;
    height: 2.5px;
    border-radius: 20px;
    transition: .3s cubic-bezier(0.77, 0, 0.175, 1);
}

.ui-mn-hamburger span::before {
    top: -8px;
}

.ui-mn-hamburger span::after {
    top: 8px;
}

.ui-mn-options {
    display: flex;
    align-items: center;
    justify-content: center;
}

/*HAMBURGER*/

/*LANGUAGE SELECT*/
.ui-mn-lang-select.__sm {
    display: none;
}

.ui-mn-lang-select.__lg {
    display: block;
}

select.ui-mn-lang-select {
    display: block;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #4e5157;
    padding: 8px 16px;
    background: #fff;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 5px;
    width: 200px;
    margin-bottom: 0;
    height: auto;
}

/*LANGUAGE SELECT*/

/*OPEN MENU*/
.ui-mn-list-wrapper {
    opacity: 0;
    pointer-events: none;
    float: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99999;
    bottom: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.ui-mn-list-wrapper.dl-open {
    opacity: 1;
    pointer-events: auto;
}

.dl-navbar-inner-wrapper {
    position: relative;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.ui-mn-close-btn {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
    cursor: pointer;
    margin-top: 16px;
    margin-right: 12px;
    margin-bottom: 16px;
}

ul.ui-mn-list {
    padding: 3px 22px 0 0;
}

.ui-mn-list-item {
    font-size: 28px;
    line-height: 38px;
    text-align: right;
    letter-spacing: 0.3px;
    margin-bottom: 16px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    list-style: none;
}

/*OPEN MENU*/

@media screen and (max-width: 767px) {
    .ui-mn-wrapper {
        padding: 8px 16px;
    }

    .ui-mn-wrapper.ui-scroll {
        padding: 4px 16px;
    }

    .ui-mn-hamburger {
        padding: 16px 0 16px;
    }

    .ui-mn-lang-select.__sm {
        display: block;
        width: 100%;
        margin-bottom: 8px;
    }

    .ui-mn-lang-select.__lg {
        display: none;
    }

    .ui-mn-list-item {
        font-size: 22px;
        line-height: 30px;
        display: flex;
        justify-content: flex-end;
    }
}

a>* {
    pointer-events: none;
}

.lang-dropdown {
    display: none;
    background-color: #F5F5F5;
    margin-right: 2em;

    &.show-in-mobile {
        margin-right: 0;
        display: inherit;

        @media screen and (min-width: 400px) {
            display: none;
        }
    }

    @media screen and (min-width: 400px) {
        display: inherit;
    }
}

.menu-text {
    display: none;

    @media screen and (min-width: 767px) {
        display: inherit;
    }
}
</style>
