export default [{
    id: "4009",
    name: "Alberta Medical Center",
}, {
    id: "4008",
    name: "Edmonton General Hospital",
}, {
    id: "4006",
    name: "Toronto Memorial Hospital",
}, {
    id: "4005",
    name: "Vancouver General Hospital",
}];