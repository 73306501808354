export const localeList = {
    af: "Afrikaans", // Afrikaans
    ar: "العربية", // Arabic
    bd: "Belgisch-Nederlands", // Belgian Dutch
    bf: "Français de Belgique", // Belgian French
    bg: "български", // Bulgarian
    ck: "傳統中文", // Traditional Chinese (Hong Kong)
    cs: "Čeština", // Czech
    ct: "繁體中文", // Traditional Chinese (Taiwan)
    da: "Dansk", // Danish
    de: "Deutsch", // German
    el: "ελληνικα", // Greek
    en: "English", // English
    es: "Español", // Spanish (EU)
    fc: "Français canadien", // French Canadian
    fr: "Français", // French (EU)
    gs: "Schweizerdeutsch", // Swiss German
    he: "עברית", // Hebrew
    hr: "Hrvatski", // Croatian
    hu: "Magyar", // Hungarian
    it: "Italiano", // Italian
    iw: "Svizzero Italiano", // Swiss Italian
    ja: "日本語", // Japanese
    ka: "ქართული", // Georgian
    ko: "한국어", // Korean
    lp: "Español latinoamericano", // Spanish LATAM
    lt: "Lietuviškai", // Lithuanian
    lv: "Latviski", // Latvia
    ms: "Melayu",
    nl: "Nederlands", // Dutch
    no: "Norsk", // Norwegian
    pl: "Polski", // Polish
    pt: "Português", // Portuguese
    ro: "Română", // Romanian
    ru: "Русский", // Russian
    sf: "Français de Suisse", // Swiss French
    sk: "Slovensky", // Slovak
    sl: "Slovenčina", // Slovenian
    sp: "Español-EE.UU", // US Spanish
    sr: "Српски", // Serbian
    st: "Sesotho sa Borwa", // Southern Sotho
    sv: "Svenska", // Swedish
    sy: "Српски (ћирилица)", // Serbian (Cyrillic)
    ta: "", //Tamil
    tl: "Tagalog", // Tagalog
    tr: "Türkçe", // Turkish
    th: "ไทย",
    xh: "isiXhosa", // Xhosa
    zh: "简体中文", // Simplified Chinese
    zu: "Zulu" // Zulu
}