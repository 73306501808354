import Vue from 'vue';
import { passwords, cookieExpiryTimeInMins } from "@/config";


var auth = {

    /**
     * Summary Logs user out.
     * Description logs a user out by removing passwords for login and review/amend sites
     * and then sends user to another page eg login page.
     * @param  {Object} context             You will usually pass 'this' as a value
     * @param  {string} passwordKey
     * @param  {string} reviewPasswordkey
     * @param  {string} pathAfterLogout
     */
    logout: function(context, pathAfterLogout) {
        for (let pass of passwords) {
            context.$cookies.remove(pass.key);
        }
        context.$router.push({ path: pathAfterLogout });
    },


    login: function(context, inputPassword) {
        console.log("pass input is", inputPassword);
        for (let pass of passwords) {
            if (pass.value === inputPassword) {
                console.log("pass is", pass);
                this.finalizeLogin(context, pass.key, inputPassword, pass.toRoute);
            }
        }

        context.error = true;
    },

    finalizeLogin: function(context, passwordKey, inputPassword, pathAfterLogin) {
        context.error = false;
        context.$cookies.set(passwordKey, inputPassword, cookieExpiryTimeInMins * 60);
        this.redirectAfterLogin(context, pathAfterLogin);
    },

    redirectAfterLogin: function(context, pathAfterLogin) {
        context.$router.replace(pathAfterLogin);
    },

    isPasswordInCookie: function(passwords) {
        for (let pass of passwords) {
            if (Vue.$cookies.get(pass.key) === pass.value) {
                return true;
            }
        }
        return false;
    },

    /*
    guardPage: function({ next }) {
        console.log("the 2nd this in here is: ", this)
        if (this.isPasswordInCookie(passwords)) {
            next();
        } else {
            next("/login");
        }
    },
    */
}


export function guardPage({ next }) {
    console.log("the 2nd this in here is: ", this)
    if (auth.isPasswordInCookie(passwords)) {
        next();
    } else {
        next("/login");
    }
}

export default auth;