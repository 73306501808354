<template>
<section class="ui-section">
    <div class="ui-max-width">
        
        <div class="flex ui-fle-wrap">
            <div class="ui-flex-items-100">
                <img class="headmast__banner-image img-fluid" src="@/assets/images/micropscope.png">
            </div>
        <div class="ui-ts-text-wrap ui-flex-items-100 __text">
        <div class="ui-head ui-flex-items-100">
                <h3 v-html="$t('abouthead')"></h3>
            </div>
            <p class="lg-text">{{$t('about1') }}</p>
            <p class="mb-0">{{$t('about2') }}</p>
            <p>{{$t('about3') }}</p>
            <p>{{$t('about4') }}</p>
            <!-- <p>{{$t('about5') }}</p> -->
        </div>
        </div>
    </div>
</section>
</template>

<script>
//import UiHeadingVue from '../../components/UiHeading.vue'
export default {
 components: {
        //"ui-head": UiHeadingVue
    }
}
</script>


<style lang="scss" scoped>
.ui-section{
    @include about-bg-image;
}
/*EDIT COLOURS HERE*/
.ui-ts-heading {
    color: #4e5157;
}

.ui-ts-heading span {
    color: #39a8e3;
}

.ui-ts-subheading {
    color: #39a8e3;
}

.ui-ts-subheading {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.005em;
    text-align: left;
    margin-bottom: 8px;
}

.ui-ts-text-wrap {
    margin-left: 0;
}

.ui-ts-ul {
    list-style: disc;
    margin-top: 12px;
    margin-bottom: 12px;
}

.ui-ts-ol {
    list-style: decimal;
    margin-top: 12px;
    margin-bottom: 12px;
}

.ui-ts-li {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 6px;
    padding-left: 8px;
}
.ui-fle-wrap{
    flex-wrap: nowrap;
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
    }
}

.ui-flex-items-100{
    width: 40%;
    &.__text{
        width: 60%;
        margin-left: 50px;
        @media screen and (max-width: 768px) {
            width: 65%;
            margin-left: 20px;
        }
        @media screen and (max-width: 767px) {
            width: 100%;
            margin-left: 0;
        }
    }
    @media screen and (max-width: 768px) {
        width: 28%;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}
</style>
