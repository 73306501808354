<template>
<section class="faq-section ui-section">
    <div class="ui-max-width">
        <ui-head class="txt-center">
            <template #head>
                <h3 class="faq-head" v-html="$t('faq_head')"></h3>
            </template>
            <template #subhead>
                <p v-html="$t('faq_intro')"></p>
            </template>
        </ui-head>
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq1')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq1_a')"></p>
            </template>
        </ui-faq>
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq2')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq2_a')"></p>
            </template>
        </ui-faq>
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq3')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq3_a')"></p>
            </template>
        </ui-faq>
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq4')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq4_a')"></p>
            </template>
        </ui-faq>
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq5')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq5_a')"></p>
                <p v-html="$t('faq5_a1')"></p>
            </template>
        </ui-faq>
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq6')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq6_a')"></p>
            </template>
        </ui-faq>
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq7')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq7_a')"></p>
            </template>
        </ui-faq>
    </div>

</section>
</template>

<script>
import UiFaqVue from '../../components/UiFaq.vue'
import UiHeadingVue from '../../components/UiHeading.vue'
export default {

    components: {
        "ui-faq": UiFaqVue,
        "ui-head": UiHeadingVue
    }

}
</script>

<style lang="scss">

.faq-head {
    color: $primary-color;
}

.faq-section {
    background: $faq-section-bg-color;

    .ui-head {
        color: $accent-color;
    }

    .ui-faqs-item {
        &.__open {
            border-bottom-color: $faq-hr-onopen-color;

            .ui-faqs-btn {
                i {
                    color: #fff;
                }
            }
        }
    }
}

.ui-faqs-item {
    border-bottom-color: #333;
}

.faq-comp {
    .ui-faqs-btn {
        background-color: $faq-btn-color;
    }
}
</style>
