<template>
    <div class="leftee-layout">
        <!-- @slot @slot Use this slot to provide content of the layout -->
        <slot></slot>
    </div>
</template>

<script>
/**
 * Use this layout to arrange items starting from the left
 * @displayName Leftee Layout
 */
export default {

    name: 'leftee-layout'
    
}
</script>

<style lang="scss">
.leftee-layout{
    display: flex;
    justify-content: left;
}
</style>