<template>
<!--
    triggered on click
    @event click
  -->
<button class="btn" @click="$emit('click')" :disabled='isDisabled'>

    <!-- @slot Use this slot to provide content of the button -->
    <slot></slot>
</button>
</template>

<script>
/**
 * Button for Consent Navigator Template
 * @displayName CN Button
 */
export default {
    name: "ui-button",

    props: {
        isDisabled: {
            type: Boolean,
            required: false,
            default: false,
        }
    }
};
</script>

<style lang="scss">
.btn {
    border-radius: 5px;
    padding: 10px 20px;
    text-decoration: none;
    transition: all .3s ease;
    //border: 1px solid #887373;
    border: none;
    background: #ffffff;
    //width: 100%;
    cursor: pointer;

    &:disabled {
        background: #a8b1bd;

        &:hover{
            cursor: not-allowed;
            background: #a8b1bd;
        }
    }

    &:hover {
        background: #39a5d3;
        color: #fff;
    }

    &:active {
        background: #39a5d3;
        color: #fff;
    }

    &:focus {
        background: #2d96c4;
    }

    &--primary {
        background: #005172;
        color: white;

        &:hover {
            background: #2e92bd;
            color: #fff;
        }

        &:active {
            background: #2e92bd;
            color: #fff;
        }

        &:focus {
            background: #2e92bd;
        }
    }

    &--small {
        padding: 5px 10px;
    }

    &--bordered {
        border: 1px solid #DEDEDE;
    }

    &--text {
        background: none;
        border: none;
        color: white;

        &:hover {
            background: none;
        }
    }
}
</style>

<docs lang="md">
Use this button in Consent Navigators.

## Examples

Simple button:

```vue
<ui-button class="btn" >Click Me</ui-button>
```

Button containing custom tags:

```vue
<ui-button class="btn">Text with <b>bold</b></ui-button>
```

Bordered button:

```vue
<ui-button class="btn btn--bordered" >Click Me</ui-button>
```

</docs>
