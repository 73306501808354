<template>
<section class="ui-share ui-section">
    <div class="ui-share__body ui-width-limit">
        <div class="txt-center">
            <div class="ui-head">
                <h3 v-html="$t('share_head')"></h3>
            </div>
            <p class="">{{$t('share_subhead')}}</p>
        </div>
        <layout-centered>
            <div class="ui-share__copy">
                <span class="ui-share__link-icon"><i class="fas fa-link link-icon"></i></span>
                <div style="padding:4px">
                    <input ref="ui_url" class="ui-share__link" type="text" :value="$t('share_link')" disabled>
                    <ui-tooltip :isVisible="isTooltipVisible">
                        <template #component>
                            <ui-button class="share-btn btn--primary" @click="copyLink(urlElm.value)">{{$t('share_copy')}}</ui-button>
                        </template>
                        <template #alert-text>Copied</template>
                    </ui-tooltip>

                </div>
            </div>
        </layout-centered>

    </div>
</section>
</template>

<script>
import CenteredLayoutVue from '../../components/layout/CenteredLayout.vue'
import UiButtonVue from '../../components/UiButton.vue'
//import UiHeadingVue from '../../components/UiHeading.vue'
import UiTooltipVue from '../../components/UiTooltip.vue'
export default {
    components: {
        "ui-button": UiButtonVue,
        "layout-centered": CenteredLayoutVue,
       // "ui-head": UiHeadingVue,
        "ui-tooltip": UiTooltipVue
    },
    data() {
        return {
            urlElm: null,
            isTooltipVisible: false,
        }
    },
    mounted() {
        this.urlElm = this.$refs.ui_url
    },
    methods: {
        copyLink: function () {
            this.urlElm.disabled = false;
            this.urlElm.select();
            document.execCommand("copy");
            this.urlElm.disabled = true;
            this.isTooltipVisible = true
            window.setTimeout(this.removeTooltip, 1500)
        },

        removeTooltip: function () {
            this.isTooltipVisible = false
        }
    },
}
</script>

<style lang="scss" scoped>
.ui-share {
    background: $share-section-bg-color;

    &__body {
        color: white;
    }

    &__copy {
        background: #F5F5F5;
        border-radius: 4px;
        display: flex;
        align-items: stretch;
    }

    &__link {
        color: #4e5157;
        padding: 0 16px;
        background: transparent;
        border: none;
        max-width: 100px;
        text-align: center;
        font-size: 16px;
        @media screen and (min-width: 767px) {
            min-width: 290px;
        }
        &:disabled {
            -webkit-text-fill-color: #4e5157;
            color: #4e5157;
        }

        &:focus {
            background-color: none;
            outline: none;
        }

        @media screen and (min-width: 767px) {
            font-size: 18px;
            max-width: 400px;
        }
    }

    &__link-icon {
        color: #4e5157;
        padding: 8px 16px;
        border-right: 0.5px solid #cfcfcf;
        display: flex;
        align-items: center;
    }
}

.share-btn{
    background-color: $share-btn-color;

    &:hover{
        background-color: darken($share-btn-color, 10%);
    }
}
</style>
