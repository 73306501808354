export const patientConnectUrl = {
    base: "https://gc.longboat.com",
    rel: "/api/v1/Longboat/9/patient_connect",
    get absolutePath() {
        return this.base + this.rel;
    },
}

export const patientConnectHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    //'Authorization': '',
}

export const gMapConsentCookieKey = "gMapConsentCookie";
export const gMapConsentCookieExpiryTimeInMins = 10;
export const googleMapApiKey = "AIzaSyDYt4g3PR9n2P5HF7NivSCidrKFvj9Nojg";
export const googleMapUrl = `https://maps.googleapis.com/maps/api/js?key=${googleMapApiKey}`;

// Animation
export const isPageAnimationEnabled = true;

// Login Page Style
export const isDefaultStyle = true; // true or false

//Images
export const logoPath = "Logo.png";


//Pendo Analytics
export const pendoKey = '055e0a8b-b58d-43b8-6bc3-991ad9ea3974';
export const studyURL = "https://lbdemo1.longboat.com";
export const sponsorName = "Longboat";
export const studyId = "LBDEMO1";
export const AppType = "DEMO";
export const pendoAccountName = studyId + '_' + AppType;

// Authentication
export const cookieExpiryTimeInMins = 10;
export const passwords = [{
    key: "pass", // key used for storing this password in cookie
    value: "Longboat!", // password
    toRoute: "/", // go to this route/url after successful authentication
}, ];

// i18n localisation configuration
export const defaultLocale = "en";
export const supportedLocales = [
    "en",
    // "es"
];

// Google map study Sites
export let gMapOptions = {
    center: {
        lat: 33.9466277,
        lng: -84.3320862,
    },
    scrollwheel: false,
    zoom: 10,
    styles: [{
            "featureType": "all",
            "elementType": "geometry.fill",
            "stylers": [{
                "weight": "2.00"
            }]
        },
        {
            "featureType": "all",
            "elementType": "geometry.stroke",
            "stylers": [{
                "color": "#9c9c9c"
            }]
        },
        {
            "featureType": "all",
            "elementType": "labels.text",
            "stylers": [{
                "visibility": "on"
            }]
        },
        {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [{
                "color": "#f2f2f2"
            }]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry.fill",
            "stylers": [{
                "color": "#ffffff"
            }]
        },
        {
            "featureType": "landscape.man_made",
            "elementType": "geometry.fill",
            "stylers": [{
                "color": "#ffffff"
            }]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [{
                "visibility": "off"
            }]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [{
                    "saturation": -100
                },
                {
                    "lightness": 45
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry.fill",
            "stylers": [{
                "color": "#eeeeee"
            }]
        },
        {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [{
                "color": "#7b7b7b"
            }]
        },
        {
            "featureType": "road",
            "elementType": "labels.text.stroke",
            "stylers": [{
                "color": "#ffffff"
            }]
        },
        {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [{
                "visibility": "simplified"
            }]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [{
                "visibility": "off"
            }]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [{
                "visibility": "off"
            }]
        },
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [{
                    "color": "#46bcec"
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry.fill",
            "stylers": [{
                "color": "#c8d7d4"
            }]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [{
                "color": "#070707"
            }]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.stroke",
            "stylers": [{
                "color": "#ffffff"
            }]
        }, {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [{ "visibility": "off" }]
        }
    ]
}
export let getSites = (googleMapObj, mapObj) => {
    let icon = {
        url: "/map.png",
    };
    let sites = [
        new googleMapObj.maps.Marker({
            active: true,
            title: "Alberta Medical Center",
            site_no: "",
            address: "Alberta,Canada",
            email: "info@albertamedicalcenter.com",
            position: {
                lat: 49.685178,
                lng: -112.8155905,
            },
            icon: icon,
            map: mapObj,
        }),
        new googleMapObj.maps.Marker({
            active: true,
            title: "Edmonton General Hospital",
            site_no: "",
            address: "Edmonton, Canada",
            email: "info@edmontongeneralhospital.com",
            position: {
                lat: 53.497084,
                lng: -113.5598073,
            },
            icon: icon,
            map: mapObj,
        }),
        new googleMapObj.maps.Marker({
            active: true,
            title: "Toronto Memorial Hospital",
            site_no: "",
            address: "Toronto, Canada",
            email: "info@torontohemorialhospital.com",
            position: {
                lat: 43.65861160000001,
                lng: -79.38333589999999,
            },
            icon: icon,
            map: mapObj,
        }),
        new googleMapObj.maps.Marker({
            active: true,
            title: "Vancouver General Hospital",
            site_no: "",
            address: "Vancouver, Canada",
            email: "info@vancouvergeneralhospital.com",
            position: {
                lat: 49.26179279999999,
                lng: -123.1222622,
            },
            icon: icon,
            map: mapObj,
        }),
        new googleMapObj.maps.Marker({
            active: true,
            title: "London Regent Hospital",
            site_no: "",
            address: "United Kingdom",
            email: "info@londonregenthospital.com",
            position: {
                lat: 51.5292262,
                lng: -0.08805489999999999,
            },
            icon: icon,
            map: mapObj,
        }),
        new googleMapObj.maps.Marker({
            active: true,
            title: "Cleveland Medical Center",
            site_no: "",
            address: "United States",
            email: "info@clevelandmedicalcenter.com",
            position: {
                lat: 41.4963405,
                lng: -81.6719914,
            },
            icon: icon,
            map: mapObj,
        }),
        new googleMapObj.maps.Marker({
            active: true,
            title: "Maryland Clinic",
            site_no: "",
            address: "United States",
            email: "info@marylandclinic.com",
            position: {
                lat: 39.0041504,
                lng: -77.03917539999999,
            },
            icon: icon,
            map: mapObj,
        }),
        new googleMapObj.maps.Marker({
            active: true,
            title: "Miami Memorial",
            site_no: "",
            address: "United States",
            email: "info@miamimemorial.com",
            position: {
                lat: 25.7870967,
                lng: -80.2042436,
            },
            icon: icon,
            map: mapObj,
        }),
        new googleMapObj.maps.Marker({
            active: true,
            title: "San Francisco Regional Hospital",
            site_no: "",
            address: "United States",
            email: "info@sanfranciscoregional.com",
            position: {
                lat: 37.7929789,
                lng: -122.4212424,
            },
            icon: icon,
            map: mapObj,
        }),
        new googleMapObj.maps.Marker({
            active: true,
            title: "Kentucky Memorial Hospital",
            site_no: "",
            address: "United States",
            email: "info@kentuckymemorial.com",
            position: {
                lat: 38.3893068,
                lng: -84.3121264,
            },
            icon: icon,
            map: mapObj,
        }),
        new googleMapObj.maps.Marker({
            active: true,
            title: "Universatsklinikum Freiburg",
            site_no: "",
            address: "Germany",
            email: "universatsklinikumfreiburg@email.com",
            position: {
                lat: 47.9990077,
                lng: 7.842104299999999,
            },
            icon: icon,
            map: mapObj,
        }),
        new googleMapObj.maps.Marker({
            active: true,
            title: "St Barbara Klinik",
            site_no: "",
            address: "Germany",
            email: "stbarbaraklinik@email.com",
            position: {
                lat: 51.7079078,
                lng: 7.8416983,
            },
            icon: icon,
            map: mapObj,
        }), new googleMapObj.maps.Marker({
            active: true,
            title: "Universitatsklinikum Tubingen",
            site_no: "",
            address: "Germany",
            email: "universitatsklinikum@email.com",
            position: {
                lat: 48.5297568,
                lng: -9.0381857,
            },
            icon: icon,
            map: mapObj,
        }), new googleMapObj.maps.Marker({
            active: true,
            title: "Renji Hospital Shanghai Jiaotong University School of Medicine",
            site_no: "",
            address: "China",
            email: "renjihospital@email.com",
            position: {
                lat: 31.221517,
                lng: 121.544379,
            },
            icon: icon,
            map: mapObj,
        }), new googleMapObj.maps.Marker({
            active: true,
            title: "Beijing Jishuitan Hospital",
            site_no: "",
            address: "China",
            email: "beijingjishuitan@email.com",
            position: {
                lat: 39.943003,
                lng: 116.3760219,
            },
            icon: icon,
            map: mapObj,
        }), new googleMapObj.maps.Marker({
            active: true,
            title: "ShengJing Hospital of China Medical University",
            site_no: "",
            address: "dddddddd",
            email: "shengJinghospital@email.com",
            position: {
                lat: 41.773454,
                lng: 123.427134,
            },
            icon: icon,
            map: mapObj,
        }), new googleMapObj.maps.Marker({
            active: true,
            title: "Shanghai Minhang Central Hospital ",
            site_no: "",
            address: "China",
            email: "shanghaiminhang@email.com",
            position: {
                lat: 31.1403629,
                lng: 121.393299,
            },
            icon: icon,
            map: mapObj,
        }), new googleMapObj.maps.Marker({
            active: true,
            title: "University Hospital London",
            site_no: "",
            address: "United Kingdom",
            email: "universityhospital@email.com",
            position: {
                lat: 51.5249486,
                lng: -0.1371557,
            },
            icon: icon,
            map: mapObj,
        }), new googleMapObj.maps.Marker({
            active: true,
            title: "Manchester University Hospital",
            site_no: "",
            address: "United Kingdom",
            email: "manchesteruniversity@email.com",
            position: {
                lat: 53.46207949999999,
                lng: -2.226656,
            },
            icon: icon,
            map: mapObj,
        }), new googleMapObj.maps.Marker({
            active: true,
            title: "Cardiff University Hospital",
            site_no: "",
            address: "United Kingdom",
            email: "cardiffuniversity@email.com",
            position: {
                lat: 51.5062635,
                lng: -3.1889835,
            },
            icon: icon,
            map: mapObj,
        }), new googleMapObj.maps.Marker({
            active: true,
            title: "Royal Edinburgh Hospital",
            site_no: "",
            address: "United Kingdom",
            email: "royaledinburgh@email.com",
            position: {
                lat: 55.9270101,
                lng: -3.2168556,
            },
            icon: icon,
            map: mapObj,
        }), new googleMapObj.maps.Marker({
            active: true,
            title: "Central Care Ontario",
            site_no: "",
            address: "Canada",
            email: "centralcareontario@email.com",
            position: {
                lat: 45.3236141,
                lng: -75.6624351,
            },
            icon: icon,
            map: mapObj,
        }), new googleMapObj.maps.Marker({
            active: true,
            title: "Calgary Research Campus",
            site_no: "",
            address: "Canada",
            email: "calgaryresearchcampus@email.com",
            position: {
                lat: 51.0372151,
                lng: -114.0301147,
            },
            icon: icon,
            map: mapObj,
        }), new googleMapObj.maps.Marker({
            active: true,
            title: "Good Samaritan Hospital",
            site_no: "",
            address: "United States",
            email: "goodsamaritanhospital@email.com",
            position: {
                lat: 39.14520570000001,
                lng: -84.5169757,
            },
            icon: icon,
            map: mapObj,
        }), new googleMapObj.maps.Marker({
            active: true,
            title: "Massachusetts General Hospital",
            site_no: "",
            address: "United States",
            email: "massachusettsgeneralhospital@email.com",
            position: {
                lat: 42.36475590000001,
                lng: -71.1032591,
            },
            icon: icon,
            map: mapObj,
        }), new googleMapObj.maps.Marker({
            active: true,
            title: "Bellevue Hospital Center",
            site_no: "",
            address: "United States",
            email: "bellevuehospitalcenter@email.com",
            position: {
                lat: 40.7390729,
                lng: -73.9750165,
            },
            icon: icon,
            map: mapObj,
        }), new googleMapObj.maps.Marker({
            active: true,
            title: "California Pacific Medical Center",
            site_no: "",
            address: "United States",
            email: "californiapacific@email.com",
            position: {
                lat: 37.7876746,
                lng: -122.4228794,
            },
            icon: icon,
            map: mapObj,
        }), new googleMapObj.maps.Marker({
            active: true,
            title: "Children's Hospital",
            site_no: "",
            address: "United States",
            email: "childrenshospital@email.com",
            position: {
                lat: 42.3384848,
                lng: -71.1068479,
            },
            icon: icon,
            map: mapObj,
        }),
    ];
    return sites;
};

/* ================= Eligibility form  ===================== */
export const answers = {
    YES: "yes_a",
    NO: "no_a",
    MAYBE: "maybe_a",
    DONTKNOW: "dk_a",
};

export const eligibilityForm = (context) => [

    // Question 1
    {
        question: context.$t("question1"),
        choices: [{
                value: answers.YES,
                text: context.$t("yes_a"),
            },
            {
                value: answers.NO,
                text: context.$t("no_a"),
            },
        ],
        correctAnswers: [answers.YES],
    },

    // Question 2
    {
        question: context.$t("question2"),
        choices: [{
                value: answers.YES,
                text: context.$t("yes_a"),
            },
            {
                value: answers.NO,
                text: context.$t("no_a"),
            },
        ],
        correctAnswers: [answers.YES],
    },

    // Question 3
    {
        question: context.$t("question3"),
        choices: [{
                value: answers.YES,
                text: context.$t("yes_a"),
            },
            {
                value: answers.NO,
                text: context.$t("no_a"),
            }
        ],
        correctAnswers: [answers.YES],
    },

    // Question 4
    {
        question: context.$t("question4"),
        choices: [{
                value: answers.YES,
                text: context.$t("yes_a"),
            },
            {
                value: answers.NO,
                text: context.$t("no_a"),
            },
        ],
        correctAnswers: [answers.NO],
    },

    // Question 5
    {
        question: context.$t("question5"),
        choices: [{
                value: answers.YES,
                text: context.$t("yes_a"),
            },
            {
                value: answers.NO,
                text: context.$t("no_a"),
            },
        ],
        correctAnswers: [answers.NO],
    },

    // Question 6
    {
        question: context.$t("question6"),
        choices: [{
                value: answers.YES,
                text: context.$t("yes_a"),
            },
            {
                value: answers.NO,
                text: context.$t("no_a"),
            },
        ],
        correctAnswers: [answers.NO],
    },

    // Question 7
    {
        question: context.$t("question7"),
        choices: [{
                value: answers.YES,
                text: context.$t("yes_a"),
            },
            {
                value: answers.NO,
                text: context.$t("no_a"),
            },
        ],
        correctAnswers: [answers.NO],
    },
];


/* ================= Patient Connect Sites (Type One)  ===================== */
import ireland from "@/sites/ireland.js";
import usa from "@/sites/us.js";
import canada from "@/sites/canada.js";
export const sites = [{
        id: "ie",
        name: "Ireland",
        states: ireland,
    },
    {
        id: "us",
        name: "United States",
        states: usa,
    }, {
        id: "ca",
        name: "Canada",
        states: canada,
    },
];

/* ================= Patient Connect Sites (Type Two)  ===================== */
import irelandTwo from "@/sites/irelandTwo.js";
import unitedstatesTwo from "@/sites/usTwo.js";
import canadaTwo from "@/sites/canadaTwo.js";
export const sitesTwo = [{
    id: "ie",
    name: "Ireland",
    states: irelandTwo,
}, {
    id: "us",
    name: "United States",
    states: unitedstatesTwo,
}, {
    id: "ca",
    name: "Canada",
    states: canadaTwo,
}];