<template>
<div id="ui-modal">
    <cookie-law storageName="CNcookies" storageType="cookies" position="top" transitionName="slideFromTop" v-on:accept="accepted()" v-on:close="rejected()">
        <div slot-scope="props">
            <p class="cookie-text">
                <!-- @slot Use this slot to add the body content to be displayed -->
                <slot name="body-content"></slot>

            </p>
            <ui-button class="accept-btn btn--small btn--primary" @click="props.accept">
                <!-- @slot Use this slot to add the accept button label -->
                <slot name="accept-btn-text"></slot>
            </ui-button>
            <br />
            <ui-button class="reject-btn btn--text " @click="props.close">
                <!-- @slot Use this slot to add the reject button label -->
                <slot name="reject-btn-text"></slot>
            </ui-button>
        </div>
    </cookie-law>
</div>
</template>

<script>
import CookieLaw from "vue-cookie-law";
import UiButtonVue from "./UiButton.vue";

/**
 * Displays the Cookie Policy modal 
 * @displayName Cookie Modal
 */
export default {
    name: 'cookie-law-modal',
    components: {
        CookieLaw,
        "ui-button": UiButtonVue,
    },
    props:{
        /**
         * locale for privacy policy link
         */
       locale : {
           type: String,
           required: true,
       }
    },
    mounted() {
        if (this.$cookies.get("CNcookies") === "false") {
            document.getElementById("ui-modal").style.display = "none";
        }
    },
    methods: {
        accepted() {
            // Get the modal
            var modal = document.getElementById("ui-modal");
            modal.style.display = "none";
            /**
             * Triggers when user accepts cookies
             */
            this.$emit("consentAccepted", true);
        },
        rejected() {
            this.$cookies.set("CNcookies", "false", 0);
            // Get the modal
            var modal = document.getElementById("ui-modal");
            modal.style.display = "none";
        },
    },
};
</script>

<style lang="scss">
/* The Modal (background) */
#ui-modal {
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(75, 75, 75, 0.2);
}

.accept-btn {
    background: #2f802f;
    color: white;

    &:hover {
        background: darken(#2f802f, 10%);
    }

    /*margin-right: 20px;*/
}

.reject-btn {
    color: #007ea3 !important;

    &:hover {
        //color: #2f802f;
        text-decoration: underline;
    }
}

.Cookie--base {
    background: black !important;
    color: white !important;
    padding: 20px 16px 10px !important;
}
.Cookie {
    flex-direction: row !important;
    @media screen and (min-width: 1025px) {
        flex-direction: column !important;
    }
}
.Cookie>* {
    margin: auto;
    text-align: center;
}

.cookie-text {
    text-align: center;
    margin: 0;
    font-size: 16px;
    margin-bottom: 5px;
    a{
        color:#007ea3;
        text-decoration: none;
    }
}

.privacy-link {
    color: #007ea3 !important;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
</style>


<docs lang="md">
Use Cookie Modal to display a cookie privacy modal for users to either accept or reject cookie storage

## Examples

```html
<template>
<cookie-law-modal :locale="en">
    <template v-slot:body-content>
       This is the body content of the Cookie modal
    </template>
    <template v-slot:privacy-policy-link-text> Privacy policy </template>
    <template v-slot:accept-btn-text> Accept </template>
    <template v-slot:reject-btn-text> I do not accept cookies</template>
</cookie-law-modal>
</template>

<script>
import CookieModal from "./CookieModal.vue";

export default {
    components: {
        "cookie-law-modal": CookieModal,
    }
}
</script>

```

</docs>