<template>
<section class="footer-section">
    <div class="ui-max-width">
        <img class="footer__logo" :src="require(`@/assets/images/footer-logo.png`)" />
        <ul class='footer__link-group'>
            <li class=""><a href='#js-about' v-smooth-scroll>{{$t('nav_one')}}</a></li>
            <li class=""><a href='#js-eligible' v-smooth-scroll>{{$t('nav_two')}}</a></li>
            <li class=""><a href='#js-center' v-smooth-scroll>{{$t('nav_three')}}</a></li>
            <li class=""><a href='#js-faqs' v-smooth-scroll>{{$t('nav_four')}}</a></li>
           <!--<li class=""><a href='#js-share' v-smooth-scroll>{{$t('nav_six')}}</a></li> --> 
        </ul>
    </div>
</section>
</template>

<script>
import {
    logoPath,
} from "@/config";
export default {
    components: {

    },
    data() {
        return {
            logoSrc: logoPath,
        };
    },
}
</script>

<style lang="scss">
.footer-section {
    background-color: #4E5157;
    padding: 40px 0;

}

.footer {
    &__link-group {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        justify-content: center;

        @media screen and (min-width: 767px) {}

        li {
            list-style: none;
        }

        a {
            color: #fff;
            text-decoration: none;
            padding: 4px 16px;
            font-size: 14px;
        }
    }

    &__logo {
        width: 50%;
        display: block;
        margin: 0 auto 30px;

        @media screen and (min-width: 767px) {
            width: 30%;
        }
    }
}
</style>
