<template>
<span>
    <!--
    triggered when button is selected
    @event choiceChange
  -->
    <input class="radio__input" type="radio" :id="label" :name="groupName" :value="value" @change="$emit('choiceChange', value)">
    <label class="radio__label" :for="label">
        <!-- @slot Use this slot to provide content of the button -->
        <slot></slot>
    </label>
</span>
</template>

<script>
/**
 * @displayName Select/Choice Button
 */
export default {
    name: "select-btn",
    props: {

        /**
         * value of option
         */
        value: {
            type: String,
            required: true
        },

        /**
         * label on botton
         */
        label: {
            type: String,
            required: true
        },

        /**
         * radio button group which it belongs
         */
        groupName: {
            type: String,
            required: true
        }

    }
}
</script>

<style lang="scss">
.radio__input {
    opacity: 0;
    position: fixed;
    width: 0;
}

.radio__label {
    display: inline-block;
    background-color: #A8B1BD;
    color: white;
    padding: 8px 16px;
    font-family: sans-serif, Arial;
    font-size: 16px;
    border: 2px solid transparent;
    border-radius: 4px;
    //margin: 0 8px;
    cursor: pointer;
}

.radio__input:checked+.radio__label {
    background-color: #39a8e3;
    // border-color: #4c4;
}

.radio__input:focus+.radio__label {
    border: 2px dashed #39a8e3;
}

.radio__label:hover {
    background-color: #39a8e3;
}
</style>

<docs lang="md">
Use this to display a choice button

## Examples

```jsx
<select-btn label='bread' groupName='none'></select-btn>
```

</docs>
