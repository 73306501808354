export default [{
    id: "5007",
    name: "Cleveland Medical Center",
}, {
    id: "5002",
    name: "Maryland Clinic",
}, {
    id: "5006",
    name: "Miami Memorial",
}, {
    id: "5008",
    name: "San Francisco Regional Hospital",
}, {
    id: "5003",
    name: "Kentucky Memorial Hospital",
}];