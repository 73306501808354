<template>
<section class="headmast ui-section">
    <div class="v2-flxrow ui-header-max-width">
        <div class="v2-flxcol __nomargin">
            <p class="headmast__banner-header" v-html="$t('ats_banner_text')"></p>
            <p class="headmast__banner-text">{{$t('ats_banner_subtext')}}</p>
            <leftee-layout>
                <ui-abutton href="#js-eligible" class="elig-btn btn--primary ui-mn-smoothscroll">{{$t('ats_btn_label')}}</ui-abutton>
            </leftee-layout>
        </div>
        <div class="v2-flxcol __nomargin">
            <div class="main-img">
                <img class="headmast__banner-image img-fluid" src="@/assets/images/banner-ver2.png" @load="isBannerImageLoaded = true">
            </div>
        </div>
    </div>
</section>
</template>

<script>
import LefteeLayoutVue from '../../components/layout/LefteeLayout.vue'
import UiAnchorButtonVue from '../../components/UiAnchorButton.vue'
export default {
    name: 'about-study',
    components: {
        "ui-abutton": UiAnchorButtonVue,
        "leftee-layout": LefteeLayoutVue
    },
    data() {
        return {
            isBannerImageLoaded: false,
        }
    },
}
</script>

<style lang="scss" scoped>

.headmast {
    background-color: #F5F5F5;
    padding-top: 133px;
    padding-bottom: 50px;
    @media screen and (max-width: 1024px) {
            padding-top: 93px;
            padding-bottom: 0px;
    }
    &::before {
        content: "";
        background: #fff;
        width: 37%;
        height: 500px;
        position: absolute;
        right: 0;
        top: 0;
        border-bottom-left-radius: 0;

        @media screen and (min-width:1250px) {
            height: 600px;
        }
    }

    &__banner-header {
        font-weight: 300;
        font-size: 28px;
        line-height: 1.2em;
        letter-spacing: 1px;
        color: $banner-heading-text-color;
        text-align: left;
        margin: 0;
        max-width: 550px;

        @media screen and (min-width: 767px) {
            font-size: 40px;
            line-height: 60px;
        }
    }

    &__banner-text {
        text-align: left;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.354167px;
        color: $banner-body-text-color;
        margin: 24px 0;
        max-width: 450px;

        @media screen and (min-width: 767px) {
            font-size: 21px;
            line-height: 30px;
        }
    }

    &__banner-image {
        // border-radius: 20px;
    }
}

.v2-flxrow {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

.v2-flxcol {
    margin: 1rem .5em;
    z-index: 1;
    &.__nomargin{
        margin: 0 .5em;
    }
}

.v2-flxcol:nth-child(2) {
    position: relative;
}

@media screen and (min-width: 767px) {
    .v2-flxrow {
        flex-direction: row;
    }

    .v2-flxcol:first-child {
        flex-basis: 50%;
    }

    .v2-flxcol:nth-child(2) {
        flex-basis: 50%;
    }
}

.ui-abt {

    &-deco1 {
        position: absolute;
        top: -7%;
        left: -15%;
        opacity: .4;

        .rectangle {
            height: 88px;
            width: 221px;
            background-color: #A8B1BD;
            border-radius: 70px;

            @media screen and (min-width: 1000px) {
                height: 150px;
                width: 300px;
            }
        }
    }

    &-deco2 {
        background: $deco-color;
        padding: 4px;
        width: 60px;
        box-shadow: 8px 8px 49px rgba(0, 0, 0, 0.15);
        border-radius: 12px;
        position: absolute;
        bottom: -15px;
        left: -20px;
        z-index: 9;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .ui-circle {
            height: 18px;
            width: 18px;
            background-color: #F5F5F5;
            border-radius: 50%;
        }

        .ui-rec {
            width: 54px;
            height: 8px;
            background-color: #F5F5F5;
            border-radius: 30px;
            margin-top: 8px;
        }

        .ui-rec:nth-child(3) {
            width: 35px;
            margin-bottom: 16px;
        }

        @media screen and (min-width: 1000px) {}
    }

    &-deco4 {
        background: white;
        padding: 4px 8px;
        width: 90px;
        box-shadow: 8px 8px 49px rgba(0, 0, 0, 0.15);
        border-radius: 12px;
        position: absolute;
        bottom: 71px;
        right: -16px;
        z-index: 9;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media screen and (min-width: 767px) {
            bottom: 145px;
            right: -24px;
        }

        .ui-rec {
            width: 75%;
            height: 8px;
            background-color: #A8B1BD;
            border-radius: 30px;
            margin-top: 8px;
        }

        .ui-rec:nth-child(2) {
            width: 50%;
            margin-bottom: 8px;
        }

        .ui-circle {
            height: 14px;
            width: 14px;
            background-color: $deco-color;
            border-radius: 50%;
        }

    }

    &-deco3 {
        position: absolute;
        top: 20%;
        right: -24px;

        .ui-circle {
            height: 80px;
            width: 80px;
            background-color: $deco-color;
            border-radius: 50%;
        }

    }

}

.elig-btn {

        background-color: $banner-elig-btn-color;

        &:hover{
            background-color: lighten($banner-elig-btn-color, 10%);
        }
}

.main-img {
    position: relative;
}
</style>
