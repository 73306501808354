<template>
<div id="app">
    <!--
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    -->
    <router-view />
</div>
</template>

<script>
import {
    configureLocaleUrl
} from "@/plugins/i18n";
import {consent, configurePageAnimation, configureConsent} from "@/common/utils";

export default {
    components: {},
    created(){
        configureConsent(consent);
        configureLocaleUrl(this);
    },
    mounted() {
        configurePageAnimation();
    },
    unmounted() {},
    methods: {},
};
</script>

<style lang="scss">
@import "./src/assets/styles/app.scss";
</style>
