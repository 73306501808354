<template>
<div id="ui-login-wrapper">
    <header class="lang-dropdown-wrapper">
        <lang-dropdown :locales="locales" :selectedLocale="selectedOption" class="login-lang-dropdown" @localeChange="handleLocaleChangeEvent"></lang-dropdown>
    </header>
    <main id="js-login" class="login">
        <div class="login__logo-wrapper" :class="{ 'login__logo-wrapper--bg': !isDefaultStyle }">
            <img v-if="isDefaultStyle" class="login__logo" :src="require(`@/assets/images/${logoSrc}`)" alt="" />
        </div>
        <div class="login__form-wrapper">
            <div class="login__form">
                <img v-if="!isDefaultStyle" class="login__form-logo" :src="require(`@/assets/images/${logoSrc}`)" alt="" />
                <h1 class="login__form-heading" v-html="$t('login_welcome_title')"></h1>
                <p class="login__form-text" v-html="$t('login_welcome_text_html')"></p>
                <form class="login-form" v-on:submit.prevent="login">
                    <label :aria-label="$t('login_password')" class="login__password-input-label">
                        <input class="login__password-input" :class="{ 'login__password-input--error': error }" :placeholder="$t('login_password')" type="password" name="password" v-model.trim="password" @input="removeErrorAlert" />
                    </label>
                    <div role="alert" class="d-login-alert-error" v-if="error">
                        <i class="login-error-icon fas fa-exclamation-circle"></i>
                        {{ $t("password_error_message") }}
                    </div>
                    <ui-button type="button" class="btn--primary login-btn" @click="login()">
                        {{ $t("login_button") }}
                    </ui-button>
                </form>
            </div>
            <copyright-footer class="login-copyright" :locale="$i18n.locale"></copyright-footer>
        </div>
    </main>
    <aside>
        <cookie-modal :locale='$i18n.locale' v-on:consentAccepted="acceptConsent">
            <template #body-content> {{ $t("c_text") }} <br /><span v-html="$t('c_text_two')"></span></template>
            <template #accept-btn-text>{{$t('c_accept')}}</template>
            <template #reject-btn-text>{{$t('c_nocookie')}}</template>
        </cookie-modal>
    </aside>
</div>
</template>

<script>
import CookieModal from "@/components/CookieModal.vue";
import {consent, resizeOverlay, wistiaCookieTracking, handleLocaleChangeEvent, setPageTitle} from "@/common/utils";
import auth from "@/plugins/auth";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import UiButtonVue from "@/components/UiButton.vue";
import CopyRightLabel from "@/components/CopyRightLabel.vue";
import {
    isDefaultStyle,
    logoPath,
} from "@/config";
import {
    getpreferredLocales, 
    setCurrentLocale, 
} from "@/plugins/i18n";

export default {
    components: {
        "lang-dropdown": LocaleSwitcher,
        "ui-button": UiButtonVue,
        "copyright-footer": CopyRightLabel,
        "cookie-modal": CookieModal,
    },
    data() {
        return {
            isDefaultStyle: isDefaultStyle,
            error: null,
            password: null,
            locales: getpreferredLocales(),
            logoSrc: logoPath,
            selectedOption: this.$i18n.locale
        };
    },
    mounted() {
        resizeOverlay("js-login");
        document.title = this.$t('site_title');
    },
    methods: {
        login() {
            auth.login(this, this.password);
        },
        removeErrorAlert() {
            this.error = false;
        },
        acceptConsent() {
            consent.setStatus(true);
            //  console.log("Login::consent status is: " + consent.status)
            wistiaCookieTracking(true);
        },
        handleLocaleChangeEvent(locale) {

            this.selectedOption = locale
            setCurrentLocale(locale)
            handleLocaleChangeEvent(this,locale);
            setPageTitle(this.$t('site_title'))
        }
    },
};
</script>

<style lang="scss">
//@import "./src/assets/styles/settings.scss";

/* ================= Mobile-first styles  ===================== */

.login {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    &__form-logo {
        margin-bottom: 20px;
    }
    &__logo {
        @include login__logo;
    }
    &__form-logo {
        @include login__form-logo;
    }
    &__logo-wrapper {
        @include login__logo-wrapper-bg-color;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 120px 16px 40px;
        &--bg {
            @include login__logo-wrapper-bg-image;
        }
    }
    &__form-wrapper {
        @include login__form-wrapper;
        display: flex;
        flex-grow: 1;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        @media only screen and (min-width: 769px) {
            justify-content: center;
        }
    }
    &__form {
        text-align: left;
        padding: 32px 16px 16px;
        //min-width: 320px;
    }
    &__form-heading {
        color: $login_welcome_title;
        font-size: 50px;
        letter-spacing: 0;
        font-weight: 400;
        line-height: 50px;
        margin-top: 0;
        margin-bottom: 20px;
    }
    &__form-text {
        color: $login_welcome_text;
        margin-bottom: 8px;
    }
    &__password-input {
        margin: 8px 0 0;
        padding: 15px;
        background: #fff;
        border: 1px solid #e8e8e8;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
        &--error {
            border-color: $login-error-input-border-color;
        }
        &::-webkit-input-placeholder {
            /* Edge */
            color: #4e5157;
            //font-style: italic;
            letter-spacing: 1px;
        }
        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #4e5157;
            // font-style: italic;
            letter-spacing: 1px;
        }
        &::placeholder {
            color: #4e5157;
            // font-style: italic;
            letter-spacing: 1px;
        }
    }
    &__password-input-label {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 16px;
    }
}

.lang-dropdown-wrapper {
    width: 100%;
    position: absolute;
    top: 32px;
    right: 16px;
    display: flex;
    justify-content: flex-end;
    margin: auto;
    opacity: 1;
}

.login-lang-dropdown {
    // margin-right: 20px;
}

.d-login-alert-error {
    color: $login-error-text-color;
    padding: 10px;
    font-style: italic;
    letter-spacing: 0.5px;
    padding: 10px 10px 0;
}

.login-btn {
    margin-top: 32px;
    background-color: $login-btn-bg-color;
    color: white;
    width: 100%;
    &:hover {
        background-color: $login-btn-bg-onhover;
    }
    &:active {
        background: $login-btn-bg-color;
        color: #fff;
    }
    &:focus {
        background: $login-btn-bg-onfocus;
    }
}

.login-copyright{
    position: absolute;
    bottom: 32px;
    right: 32px;
}

.login-copyright .copyright__text {
    color: $login-copyright-text-color;
}


/* ================= Screen size 1000px and above styles  ===================== */

@media only screen and (min-width: 1000px) {
    .lang-dropdown-wrapper {
        right: 40px;
    }
    .login {
        flex-direction: row;
        &__form-wrapper {
            align-items: center;
            width: 0; // for animation
        }
        &__form {
            width: 432px; //350px;
        }
        &__logo-wrapper {
            width: 50%;
        }
        &__logo-wrapper {
            //height: 100vh;
            top: 40px;
            padding: 0 10px;
        }
    }
}


/*-- ==========================================================================
                                    ANIMATION ⬇
==========================================================================  */

.login {
    &__logo,
    &__logo-wrapper {
        transition: all 0.6s;
        transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
    }
}

.lang-dropdown-wrapper {
    transition: opacity 0.5s;
    transition-delay: 0.2s;
}

.animate {
    .lang-dropdown-wrapper {
        opacity: 0;
    }
    .login {
        &__logo {
            @include animated-login__logo;
        }
        &__logo-wrapper {
            width: 100%;
        }
        &__form-wrapper {
            display: none;
        }
    }
}
</style>
